/*
  마이렛미업 구매 페이지
*/

import React, { useCallback, useEffect, useState } from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import { Desktop, Mobile } from "../../components/layouts/mediaQuery"
import LeftSideBar from "../../components/my/LeftSideBar"
import RightSideBar from "../../components/my/RightSideBar"
import MyContainer from "../../containers/MyContainer"
import PrivateRoute from "../../components/privateRoute"

import { rnMessageListener } from "../../utils/util"

import { navigate } from "gatsby-link"
import moment from "moment"

//구매 내 구매 이력

const COUNT = 20

const Purchase = props => {
  const { myOrderLogNo, myOrderLogInfo, getOrderLog, getOrderLogAdd, myOrderLog, myOrderLogTotal } = props
  const [filterPeriod, setFilterPeriod] = useState(0)
  const filterArr = ["TODAY", "WEEK", "MONTH", "QUARTER"]
  const filterNameArr = ["오늘", "1주일", "1개월", "3개월"]

  const [page, setPage] = useState(0)

  //get data
  const getData = async () => {
    let temp = { periodType: filterArr[filterPeriod], no: "" }
    await getOrderLog(temp)
  }

  useEffect(() => {
    setPage(0)
    getData()
  }, [filterPeriod])

  //더보기
  const increasePage = async () => {
    //add pointLogs
    let temp = { periodType: filterArr[filterPeriod], no: myOrderLogNo, myOrderLog: myOrderLog }
    await getOrderLogAdd(temp)
    // totalCount 조건
    if (COUNT * (page + 1) < myOrderLogTotal) setPage(prev => prev + 1)
  }

  //합계 fn
  const addFn = i => {
    let sum = 0
    for (let item of i) {
      sum += parseInt(item.currency)
    }
    return Number(sum || 0).toLocaleString()
  }

  const messageDataFn = useCallback(e => {
    let data = JSON.parse(e.data)
    if (data.type == "BACK") navigate(-1)
  }, [])

  useEffect(() => {
    if (window.ReactNativeWebView) {
      document.addEventListener("message", messageDataFn)
    }
    return () => {
      document.removeEventListener("message", messageDataFn)
    }
  }, [])

  return (
    <PrivateRoute>
      <Layout title="내 구매 이력">
        <SEO title="마이렛미업" />
        <div className="d-flex justify-content-center ">
          <LeftSideBar />
          <div className="container my-container">
            <div className="my-purchase-container">
              {/* 타이틀 */}
              <p className="title">내 구매 이력</p>
              {/* 요약 */}
              <div className="my-purchase-summary-view">
                <p className="summary-title">
                  <span>{filterNameArr[filterPeriod] + " "}</span> <Desktop>렛미업 스토어 결제금액</Desktop>
                  <Mobile>결제금액</Mobile>
                </p>
                <div className="flex-view">
                  <p className="text1">{Object.keys(myOrderLogInfo).length > 0 && parseInt(myOrderLogInfo.paymentAmount || 0).toLocaleString()}</p>
                  <p className="text2">원</p>
                  <p className="text3">
                    ( <span>{Object.keys(myOrderLogInfo).length > 0 && parseInt(myOrderLogInfo.savePoint || 0).toLocaleString()} LP</span> 적립 )
                  </p>
                </div>
              </div>
              {/* 필터 */}
              <div className="my-purchase-filter-view">
                <div className="flex-view">
                  <select className="form-select period-select" value={filterPeriod} onChange={e => setFilterPeriod(e.target.value)}>
                    <option value={0}>오늘</option>
                    <option value={1}>1주일</option>
                    <option value={2}>1개월</option>
                    <option value={3}>3개월</option>
                  </select>
                </div>
              </div>
              {/* 리스트 */}
              <div className="my-purchase-list-view">
                <p className="total-count">총 {myOrderLogTotal || 0}개</p>
                <div className="list-view">
                  {myOrderLog
                    .filter((i, idx) => idx < COUNT * (page + 1))
                    .map((i, idx) => (
                      <div key={idx} className="list-item">
                        <div className="list-item-header">
                          <p className="date-text">{moment(i[0].date).format("YYYY-MM-DD")}</p>

                          <p className="price-text">{addFn(i)}원</p>
                        </div>
                        {i.map((j, jdx) => (
                          <div key={jdx} className="list-item-child">
                            <div className="list-item-child-left">
                              <div className="title-view">
                                <p>{`${j.paymentDetailLarge} ${j.paymentDetailMedium}`}</p>
                                <p>{j.paymentDetailSmall}</p>
                              </div>
                              <p className="etc-text">{j.paymentDetailPeriod}</p>
                            </div>
                            <div className="list-item-child-right">
                              <p className="price-text">{Number(j.currency || 0).toLocaleString()} 원</p>
                              <p className="point-text">{Number(j.savePoint || 0).toLocaleString()} LP 적립</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
              {/* 더보기 */}
              <div className={`my-purchase-button-view ${COUNT * (page + 1) < myOrderLogTotal ? "active" : ""}`}>
                <button className="btn btn-primary font-white" onClick={increasePage}>
                  더보기
                </button>
              </div>
            </div>
          </div>
          <RightSideBar />
        </div>
      </Layout>
    </PrivateRoute>
  )
}

export default MyContainer(Purchase)
